/* Mixins */
.config-index-component .config-menu-item {
  height: 35px;
  line-height: 35px;
  padding-right: 0;
}
.config-index-component .menu-item-text {
  display: inline-block;
  width: calc(100% - 22px);
}
.config-index-component .tree-item-action-dot {
  float: right;
  padding: 0 8px;
  width: 22px;
  position: relative;
}
.config-index-component .tree-item-action-dot:hover {
  background-color: var(--primaryColor);
  color: #fff;
  font-weight: bold;
}